<template>
  <div style="background:#EDEDED;">
    <div style="text-align:center;" class="gz-pt-3">
      <img :src="imgSrc" style="width:15%;border-radius: 50%;    border: 2px solid #FFF;">
      <h2>{{description}}</h2>
    </div>
    <h2 style="text-align:center;">请输入付款金额</h2>
    <div class="gz-px-3">
      <div class="gz-layout gz-layout-row" style="    background: rgb(255, 255, 255);border-radius: 0.2em;font-size: 28px;padding: 8px;font-weight: bold;">
        <div style="padding: 10px 15px;">￥</div>
        <div class="gz-layout-item">
          <!-- <input type="number" class="amount" v-model="amount"> -->
          <calculation @confirmEvent="_confirmEvent" v-model="amount"></calculation>
        </div>
      </div>
    </div>
    <div class="gz-px-3 gz-mt-3">
      <button class="btnPay" @click="onSubmit">支付</button>
    </div>
    <div v-if="wxsdkStatus==true" class="gz-p-2 gz-pt-3">

    </div>
    <div v-else>
      页面初始化...
    </div>
  </div>
</template>
<style lang="less" scoped>
.amount {
  width: 100%;
  font-size: inherit;
  border: none;

  outline: none;
  &:focus,
  &:focus-visible {
    border: none;
  }
}
.btnPay {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 2.3;
  border-radius: 0.25rem;
  user-select: none;
  width: 100%;
}
</style>
<script>
import serverConfig from '../config/index'
import calculation from './keyboard/calculation.vue'
var wx = require('weixin-js-sdk');
import { ajax } from 'yesweb-ajax';
export default {
  components: {
    calculation
  },
  data () {
    return {
      amount: '',
      openID: '',
      wxsdkStatus: true,

      description: '',
      imgURL: '',
    }
  },
  computed: {
    imgSrc () {
      return serverConfig.serverHost + "/fileServer" + this.imgURL;
    },
  },
  created () {
    window.document.title = "向商户付款";
    var me = this;
    let code = this.$route.query.code

    ajax.get(this, '/onlinepay/H5Pay/GetWXOpenID', { code }).then(res => {
      this.openID = res.data.openID;
    })

    ajax.get(this, '/onlinepay/H5Pay/GetCurrentAttribute').then(res => {
      this.imgURL = res.data.img;
      this.description = res.data.description
    })

    // 初始化jssdk
    me.loadJSSDKConfig();
  },
  methods: {
    loadJSSDKConfig () {
      var me = this;
      ajax.get(this, '/onlinepay/H5Pay/GetWXJSSDKConfig', {
        url: window.location.href
      }).then(res => {
        debugger
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appid, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.noneStr, // 必填，生成签名的随机串
          signature: res.data.signature,// 必填，签名
          jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
        });
        wx.error(function (res) {
          alert('JSSDK配置失败：' + res);
        });
        wx.ready(function () {
          debugger
          me.wxsdkStatus = true;
        });
      })
    },
    onSubmit () {
      ajax.get(this, '/onlinepay/H5Pay/WXPay', {
        qrCode: this.$store.state.qrCode,
        openID: this.openID,
        amount: this.amount
      }).then(res => {
        wx.chooseWXPay({
          timestamp: res.data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: res.data.nonce, // 支付签名随机串，不长于 32 位
          package: 'prepay_id=' + res.data.prepay_id, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: 'RSA', // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: res.data.signature, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
          }
        });
      })
    },
    _confirmEvent (res) {
      console.log(res)
    }
  }
}
</script>